import { Action, createReducer, on } from '@ngrx/store';

import * as fromActions from './actions';
import * as fromState from './state';

export const reducer = createReducer(
  fromState.initialState,
  on(fromActions.loadRequest, (state) => ({
    ...state,
    isLoading: true,
    error: null,
  })),
  on(fromActions.loadSuccess, (state, { items }) =>
    fromState.featureAdapter.setAll(items, {
      ...state,
      isLoading: false,
      error: null,
    }),
  ),
  on(fromActions.loadFailure, (state, { error }) => ({
    ...state,
    isLoading: false,
    error,
  })),

  on(fromActions.loadInfoRequest, (state) => ({
    ...state,
    isLoading: true,
    error: null,
  })),
  on(fromActions.loadInfoSuccess, (state, { response }) => ({
    ...state,
    authVerificationMethodsInfo: response,
    isLoading: false,
    error: null,
  })),
  on(fromActions.loadInfoFailure, (state, { error }) => ({
    ...state,
    isLoading: false,
    error,
  })),
  on(fromActions.resetAuthVerificationMethodsInfoState, (state) => ({
    ...state,
    authVerificationMethodsInfo:
      fromState.initialState.authVerificationMethodsInfo,
  })),
  on(fromActions.resetState, () => fromState.initialState),
);

export function authVerificationMethodsReducer(
  state: fromState.State | undefined,
  action: Action,
) {
  return reducer(state, action);
}
