import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class AuthVerificationMethodsService {
  constructor(private http: HttpClient) {}

  load() {
    return this.http.get('general/auth_verification_methods');
  }

  connectionInfo(authVerificationMethodId: number) {
    return this.http.post(
      `user/auth_verification_methods/${authVerificationMethodId}/get_connection_info`,
      {},
    );
  }

  attach(authVerificationMethodId, data) {
    return this.http.post(
      `user/auth_verification_methods/${authVerificationMethodId}/attach`,
      data,
    );
  }

  detach(authVerificationMethodId, data) {
    return this.http.post(
      `user/auth_verification_methods/${authVerificationMethodId}/detach`,
      data,
    );
  }
}
