import { createAction, props } from '@ngrx/store';

import {
  AttachAuthVerificationMethods,
  AuthVerificationMethods,
  AuthVerificationMethodsInfo,
  DetachAuthVerificationMethods,
} from '../../models';

export const loadRequest = createAction(
  '[Auth Verification Methods] Load Request',
);

export const loadSuccess = createAction(
  '[Auth Verification Methods] Load Success',
  props<{ items: AuthVerificationMethods[] }>(),
);

export const loadFailure = createAction(
  '[Auth Verification Methods] Load Failure',
  props<{ error: any }>(),
);

export const loadInfoRequest = createAction(
  '[Auth Verification Methods] Load Info Request',
  props<{ authVerificationMethodId: number }>(),
);

export const loadInfoSuccess = createAction(
  '[Auth Verification Methods] Load Info Success',
  props<{ response: AuthVerificationMethodsInfo }>(),
);

export const loadInfoFailure = createAction(
  '[Auth Verification Methods] Load Info Failure',
  props<{ error: any }>(),
);

export const attachRequest = createAction(
  '[Auth Verification Methods] Attach Request',
  props<{
    authVerificationMethodId: number;
    data: AttachAuthVerificationMethods;
  }>(),
);

export const attachSuccess = createAction(
  '[Auth Verification Methods] Attach Success',
);

export const attachFailure = createAction(
  '[Auth Verification Methods] Attach Failure',
  props<{ error: any }>(),
);

export const detachRequest = createAction(
  '[Auth Verification Methods] Detach Request',
  props<{
    authVerificationMethodId: number;
    data: DetachAuthVerificationMethods;
  }>(),
);

export const detachSuccess = createAction(
  '[Auth Verification Methods] Detach Success',
);

export const detachFailure = createAction(
  '[Auth Verification Methods] Detach Failure',
  props<{ error: any }>(),
);

export const resetState = createAction(
  '[Auth Verification Methods] Reset State',
);
export const resetAuthVerificationMethodsInfoState = createAction(
  '[Auth Verification Methods] Reset AuthVerificationMethodsInfo State',
);
