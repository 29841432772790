import { Injectable } from '@angular/core';
import { IResponseSuccess } from '@app/core/models/response-sucess.model';
import { ErrorHandlerService } from '@app/core/services/error-handler.service';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { TranslateService } from '@ngx-translate/core';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

import { AuthVerificationMethodsService } from '../../services/auth-verification-methods.service';
import { NotificationService } from '../../ui/services/notification.service';

import * as fromActions from './actions';

@Injectable()
export class AuthVerificationMethodsStoreEffects {
  constructor(
    private dataService: AuthVerificationMethodsService,
    private actions$: Actions,
    private errorHandler: ErrorHandlerService,
    private notifications: NotificationService,
  ) {}

  load$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.loadRequest),
      switchMap(() =>
        this.dataService.load().pipe(
          map((response: IResponseSuccess) =>
            fromActions.loadSuccess({
              items: response.data,
            }),
          ),
          catchError((error) => {
            this.errorHandler.handle(error);
            return of(fromActions.loadFailure(error));
          }),
        ),
      ),
    ),
  );

  loadInfo$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.loadInfoRequest),
      switchMap(({ authVerificationMethodId }) =>
        this.dataService.connectionInfo(authVerificationMethodId).pipe(
          map((response: IResponseSuccess) =>
            fromActions.loadInfoSuccess({
              response: response.data,
            }),
          ),
          catchError((error) => {
            this.errorHandler.handle(error);
            return of(fromActions.loadInfoFailure(error));
          }),
        ),
      ),
    ),
  );

  attach$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.attachRequest),
      switchMap(({ authVerificationMethodId, data }) =>
        this.dataService.attach(authVerificationMethodId, data).pipe(
          map(() => {
            this.notifications.success(
              'create_success',
              'auth_autentication_method',
            );
            return fromActions.attachSuccess();
          }),
          catchError((error) => {
            this.errorHandler.handle(error);
            return of(fromActions.attachFailure(error));
          }),
        ),
      ),
    ),
  );

  detach$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.detachRequest),
      switchMap(({ authVerificationMethodId, data }) =>
        this.dataService.detach(authVerificationMethodId, data).pipe(
          map(() => {
            this.notifications.success(
              'delete_success',
              'auth_autentication_method',
            );
            return fromActions.detachSuccess();
          }),
          catchError((error) => {
            this.errorHandler.handle(error);
            return of(fromActions.detachFailure(error));
          }),
        ),
      ),
    ),
  );
}
